import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithRedirection } from './customBaseFetchQuery';

export const twApi = createApi({
  reducerPath: 'twApi',
  baseQuery: baseQueryWithRedirection,
  tagTypes: [
    'Equipment',
    'Location',
    'Customers',
    'Customers-Contacts',
    'Dispatchers',
    'Carriers',
    'Drivers',
    'Loads',
    'All-Loads',
    'Connections',
    'Assets',
    'InvoiceService',
    'Orders',
    'FuelTransactions',
    'Reports',
    'Divisions',
    'TenantAccountingSettings',
    'WorkOrders',
    'OtherExpenses',
    'OtherExpensesAttachment',
    'TagsList',
    'CustomerOrders',
    'ActiveTrips',
    'Trips',
    'DriverProfiles',
    'SafetyFiles',
    'SafetyFilesType',
    'DispatchNotes',
    'EquipmentProfile',
    'SafetyFilesEquipment',
    'SafetyFilesEquipmentType',
    'Validations',
    'Availability',
    'Validations',
    'SavedViews',
    'InternalFactoring',
    'DriverTimeOff',
    'EDI',
    'OrdersField',
    'TripsField',
    'RateTables',
    'RateTableCount',
    'TenantRoutingSettings',
    'Disclaimer',
    'Attachments',
    'Tenant',
    'FuelSurchargeTables',
    'EiaDieselPriceData',
    'FuelCardConfig',
    'FuelCardSyncStatus',
    'FuelCards',
    'QbDesktop',
    'Invoice'
  ],
  endpoints: () => ({})
});
