import { createSelector, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import {
  BOOKING_TYPE,
  CLIENT_TYPE,
  ORDER_STATUS,
  OrderAttachment,
  OrderCommodities,
  OrderRate,
  OrdersTripsDocumentParams,
  TZ_USAGE
} from '../../api/orders/orders.model';
import { LoadNotificationSettingsRequest } from '../../../providers/Settings/LoadNotificationSettings.model';
import { IDivisionsResponse } from '../../api/divisions/divisions.model';
import { ClientResponse, IContactClient } from '../../api/customer/customer.model';
import { LOAD_TYPE, LoadInvoiceResponse, LoadQboAttributes } from '../../api/load/load.model';
import { RowData } from '../../../components/TwAdvancedRates/RateRow';

import {
  InvoiceService,
  InvoiceServiceUnit,
  LINEHAUL_FLAT_RATE_NAME
} from '../../api/invoiceService/invoiceService.model';

import { TwAutocompleteOption } from '../../../components/TwAutocomplete/TwAutocomplete';
import {
  STOP_TYPE,
  StopStatus,
  Trip,
  TripDriverInfo,
  TripDriverOtherPay,
  TripDriverOverrideData,
  TripStop
} from '../../api/trips/trips.model';
import { Equipment, EquipmentType } from '../../api/equipment/equipment.model';
import { Driver } from '../../api/driver/driver.model';
import { RootState } from '../../store';
import { RateTableAttributes } from '../../api/rateTable/rateTable.model';
import { PROVIDER } from '../../../providers/User/User.model';

export const MIN_STOP_COUNT = 2;
export const MAX_STOPS_COUNT = 10;
export interface SliceOrderRate extends Partial<OrderRate> {
  ui_id: string;
  invoice_service?: InvoiceService;
  initial_service_option?: {
    id: string;
    label: string;
    value: unknown;
  };
  tooltip?: string;
  has_rate_error?: boolean;
  is_fuelSurcharge_row?: boolean;
}

export interface SliceStop extends Partial<TripStop> {
  order: number;
  ui_id: string;
  provider_date?: Date | null;
  provider_check_in_date?: Date | null;
  provider_check_out_date?: Date | null;
  provider_location?: TwAutocompleteOption | null;
  provider_stop_type?: TwAutocompleteOption | null;
  provider_time_from?: Date | null;
  provider_time_to?: Date | null;
  provider_check_in_time?: Date | null;
  provider_check_out_time?: Date | null;
  provider_show_time?: boolean;
  provider_include_location_notes?: boolean;
  provider_stop_identifier?: string;
  provider_edi_stop_identifier?: string;
  provider_stop_status?: StopStatus;
  provider_stop_in_time_overriden?: boolean;
  provider_stop_out_time_overriden?: boolean;
  segment_id: string;
  is_shared_stop: boolean;
  order_data?: {
    order_id: string;
    order_readable_id: string | number;
  }; // for LTL stops in a trip
}

export interface SliceTrip extends Partial<Trip> {
  order: number;
  selected_trailer: string | undefined;
  selected_truck: string | undefined;
  selected_carrier: string | undefined;
  provider_stops: SliceStop[];
  selected_drivers: Partial<TripDriverInfo>[];
  ui_id: string;
  empty_miles: number | null;
  is_empty_miles_auto: boolean;
  estimated_mileage: number | null;
  is_loaded_miles_auto: boolean;
  automatic_mileage?: number | null;
  empty_mileage_source?: PROVIDER | null;
  loaded_mileage_source?: PROVIDER | null;
}

export const DEFAULT_STOP: SliceStop = {
  order: 0,
  ui_id: '',
  provider_date: null,
  provider_location: null,
  provider_stop_type: null,
  provider_time_from: null,
  provider_time_to: null,
  provider_stop_identifier: '',
  provider_edi_stop_identifier: undefined,
  stop_type: undefined,
  provider_stop_status: undefined,
  segment_id: '',
  provider_stop_in_time_overriden: false,
  provider_stop_out_time_overriden: false,
  is_shared_stop: false
};

export const DEFAULT_STOP_1_UI_ID = nanoid();
export const DEFAULT_STOP_2_UI_ID = nanoid();
export const DEFAULT_LOAD_RATE_UI_ID = nanoid();
export const DEFAULT_LOAD_SEGMENT_UI_ID = nanoid();

export const getEmptyLoadRateServiceRow = (): RowData => ({
  ui_id: nanoid(),
  invoice_service_id: undefined,
  qbo_service_id: undefined,
  sort_order: 1
});

export const DEFAULT_SEGMENT: SliceTrip = Object.freeze({
  order: 1,
  sort_order: 1,
  ui_id: DEFAULT_LOAD_SEGMENT_UI_ID,
  selected_carrier: '',
  selected_trailer: '',
  selected_truck: '',
  selected_drivers: [{ send_driver_link: false }],
  provider_stops: [
    {
      ...DEFAULT_STOP,
      order: 1,
      ui_id: DEFAULT_STOP_1_UI_ID,
      segment_id: DEFAULT_LOAD_SEGMENT_UI_ID
    },
    {
      ...DEFAULT_STOP,
      order: 2,
      ui_id: DEFAULT_STOP_2_UI_ID,
      segment_id: DEFAULT_LOAD_SEGMENT_UI_ID
    }
  ],
  empty_miles: null,
  is_empty_miles_auto: false,
  estimated_mileage: null,
  automatic_mileage: null, // Calculated from the trips stops automatically (not sent to BE)
  is_loaded_miles_auto: true,
  instructions: '',
  loaded_mileage_source: null,
  empty_mileage_source: null
});

export interface OrderState {
  order_id?: string; // Used for the "E.D.I" flow.
  selected_trip_id?: string; // For autoselecting trip tab in Order's overlay when needed
  order_type: LOAD_TYPE;
  invoice_load_ids: string[]; // Used for create invoice flow
  invoice_division_id: string;
  status: ORDER_STATUS;
  provider_stops: SliceStop[];
  trips: SliceTrip[];
  show_advanced_rates: boolean;
  initial_load_rates: RowData[];
  order_rates: SliceOrderRate[];
  rate: number | undefined;
  timezone_usage?: TZ_USAGE;
  selected_customer: string | undefined;
  provider_customer: ClientResponse | undefined;
  selected_contact: string | undefined;
  provider_contact: IContactClient | undefined;
  selected_primary_client?: CLIENT_TYPE;
  selected_dispatcher: string | undefined;
  customer_order_number: string;
  documents: OrdersTripsDocumentParams[] | undefined;
  existing_documents: OrderAttachment[] | undefined;
  attachments: OrderAttachment[] | undefined; // TODO: Verify if this is needed as it is not being used apparently
  send_order_update: boolean;
  order_notification_settings?: LoadNotificationSettingsRequest | null;
  load_calendar_re_render_id?: string;
  duplicate_load: boolean;
  estimated_mileage?: number;
  selected_division: string | undefined;
  provider_division: IDivisionsResponse | undefined;
  is_brokered_order: boolean;
  payable_amount: number | undefined;
  order_commodities: OrderCommodities[];
  initial_location_ids?: string[]; // used for checking location association in edit location from edit load flow
  qbo_attributes?: LoadQboAttributes;
  quickbooks_enabled: boolean;
  load_rates_disabled: boolean;
  invoice: LoadInvoiceResponse | null;
  override_qbo_attributes: boolean;
  booking_type: BOOKING_TYPE;
  instructions?: string;
  total_automatic_mileage?: number; // Used to keep real route miles for Advanced rates when using and calculating miles in QTY for Per mile service
  orders_for_consolidation: { id: string; readable_id: string | number }[];
  rate_table_id?: string | null;
  is_rate_table_deleted?: boolean;
  is_rate_table_active?: boolean;
  rate_table?: RateTableAttributes | null;
  driver_pay_confirmation_modal: {
    open: boolean;
    action: any;
  };
}
export type AddDriverParams = {
  driver: Driver;
  index: number;
  selectedTruck?: string;
  selectedCarrier?: string;
  sendDriverLink?: boolean;
  selectedTrailer?: string;
};

export const ORDER_INITIAL_STATE: OrderState = {
  order_id: undefined,
  order_type: LOAD_TYPE.SIMPLE,
  invoice_load_ids: [],
  invoice_division_id: '',
  estimated_mileage: 0,
  status: ORDER_STATUS.NOT_STARTED,
  provider_stops: [
    {
      ...DEFAULT_STOP,
      order: 1,
      ui_id: DEFAULT_STOP_1_UI_ID,
      segment_id: DEFAULT_LOAD_SEGMENT_UI_ID
    },
    {
      ...DEFAULT_STOP,
      order: 2,
      ui_id: DEFAULT_STOP_2_UI_ID,
      segment_id: DEFAULT_LOAD_SEGMENT_UI_ID
    }
  ],
  trips: [{ ...DEFAULT_SEGMENT, ui_id: DEFAULT_LOAD_SEGMENT_UI_ID }],
  show_advanced_rates: true,
  initial_load_rates: [{ ui_id: DEFAULT_LOAD_RATE_UI_ID }],
  order_rates: [{ ui_id: DEFAULT_LOAD_RATE_UI_ID }],
  rate: undefined,
  timezone_usage: TZ_USAGE.LOCAL,
  provider_customer: undefined,
  provider_contact: undefined,
  selected_customer: '',
  selected_contact: '',
  selected_primary_client: CLIENT_TYPE.BROKER,
  selected_dispatcher: '',
  customer_order_number: '',
  documents: undefined,
  existing_documents: undefined,
  attachments: undefined,
  send_order_update: false,
  order_notification_settings: undefined,
  duplicate_load: false,
  selected_division: '',
  provider_division: undefined,
  is_brokered_order: false,
  payable_amount: undefined,
  order_commodities: [],
  initial_location_ids: [],
  qbo_attributes: undefined,
  quickbooks_enabled: false,
  load_rates_disabled: false,
  invoice: null,
  override_qbo_attributes: false,
  booking_type: BOOKING_TYPE.FTL,
  instructions: '',
  total_automatic_mileage: 0,
  orders_for_consolidation: [],
  rate_table_id: '',
  driver_pay_confirmation_modal: {
    open: false,
    action: null
  }
};

export const reorder = (array: any[], startIndex: number, endIndex: number): any[] => {
  // Copy of the sections
  const result = Array.from(array);
  // First item selected
  const [selectedItem] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, selectedItem);

  return result;
};

export const ordersSlice = createSlice({
  name: 'Orders',
  initialState: ORDER_INITIAL_STATE,
  reducers: {
    clearLoadState: () => {
      return {
        ...ORDER_INITIAL_STATE
      };
    },
    setLoadState: (state, action: PayloadAction<Partial<OrderState>>) => {
      // if zero stops, add two stops and one segment
      if (action.payload.provider_stops?.length === 0) {
        action.payload.provider_stops = [
          { ...DEFAULT_STOP, order: 1, ui_id: DEFAULT_STOP_1_UI_ID },
          { ...DEFAULT_STOP, order: 2, ui_id: DEFAULT_STOP_2_UI_ID }
        ];

        action.payload.trips = [{ ...DEFAULT_SEGMENT }];
      }

      // if only one stop, add one stop
      if (action.payload.provider_stops?.length === 1 && action.payload.trips?.length === 1) {
        action.payload.provider_stops?.push({
          ...DEFAULT_STOP,
          order: 2,
          ui_id: DEFAULT_STOP_2_UI_ID
        });

        action.payload.trips[0]?.provider_stops?.push({
          ...DEFAULT_STOP,
          order: 2,
          ui_id: DEFAULT_STOP_2_UI_ID
        });
      }

      return {
        ...state,
        ...action.payload
      };
    },
    setRateTableId: (state, action: PayloadAction<string | undefined>) => {
      state.rate_table_id = action.payload;
    },
    setOrderId: (state, action: PayloadAction<string | undefined>) => {
      state.order_id = action.payload;
    },
    setSelectedTripId: (state, action: PayloadAction<string | undefined>) => {
      state.selected_trip_id = action.payload;
    },
    // Not used anymore, leaving it here just in case but will be removed soon
    /*  setOrderType: (state, action: PayloadAction<LOAD_TYPE>) => {
      state.order_type = action.payload;
      // We check if we have drivers assigned, so in the case we are changing from simple to split
      // we reset driver pay

      const isSplitLoad = action.payload === LOAD_TYPE.SEGMENT;

      if (isSplitLoad) {
        const segments = state.trips.map((segment) => {
          return {
            ...segment,
            selected_drivers: segment.selected_drivers.map((driver) => {
              return {
                ...driver,
                driver_pay_override: undefined,
                driver_pay_override_data: undefined,
                trip_driver_pay_other_payment: []
              };
            })
          };
        });

        state.trips = segments;
      } else {
        const driversExist = state.trips[0].selected_drivers.length > 0;

        if (driversExist) {
          state.trips[0].selected_drivers = state.trips[0].selected_drivers.map((driver) => {
            return {
              ...driver,
              driver_pay_override: undefined,
              driver_pay_override_data: undefined,
              trip_driver_pay_other_payment: []
            };
          });
        }
      }
    }, */
    setBookingType: (state, action: PayloadAction<BOOKING_TYPE>) => {
      state.booking_type = action.payload;
    },
    updateSelectedStatus: (state, action: PayloadAction<ORDER_STATUS>) => {
      state.status = action.payload;
    },
    setDuplicateLoad: (state, action: PayloadAction<boolean>) => {
      state.duplicate_load = action.payload;
    },
    setInvoiceLoadIds: (state, action: PayloadAction<string[]>) => {
      state.invoice_load_ids = action.payload;
    },
    setInvoiceDivisionId: (state, action: PayloadAction<string>) => {
      state.invoice_division_id = action.payload;
    },
    addCustomer: (
      state,
      action: PayloadAction<{
        customer: Pick<ClientResponse, 'id' | 'type'>;
        isShipperOrBrokerActive: boolean;
      }>
    ) => {
      const { customer, isShipperOrBrokerActive } = action.payload;

      state.selected_customer = customer.id;
    },
    addEquipment: (
      state,
      action: PayloadAction<{
        equipment: Pick<Equipment, 'id' | 'type'>;
        segmentOrder: number;
        abortUpdate?: boolean;
      }>
    ) => {
      const { abortUpdate, equipment, segmentOrder } = action.payload;
      if (abortUpdate) return;

      if (equipment.id === EquipmentType.truck) {
        state.trips[segmentOrder - 1].selected_truck = equipment.id;
      }

      if (equipment.type === EquipmentType.trailer) {
        state.trips[segmentOrder - 1].selected_trailer = equipment.id;
      }
    },
    addDriver: (
      state,
      action: PayloadAction<{ params: AddDriverParams; segmentOrder: number }>
    ) => {
      const { driver, index, selectedTruck, selectedTrailer, selectedCarrier, sendDriverLink } =
        action.payload.params;

      const newDriver: TripDriverInfo = {
        id: driver.id,
        send_driver_link: sendDriverLink ?? false,
        is_primary: index === 0
      };

      state.trips[action.payload.segmentOrder - 1].selected_drivers[index] = newDriver;

      if (!state.order_id) {
        if (selectedTruck) {
          state.trips[action.payload.segmentOrder - 1].selected_truck = selectedTruck;
        }
        if (selectedTrailer) {
          state.trips[action.payload.segmentOrder - 1].selected_trailer = selectedTrailer;
        }
        if (selectedCarrier) {
          state.trips[action.payload.segmentOrder - 1].selected_carrier = selectedCarrier;
        }
      }
    },
    addCarrier: (
      state,
      action: PayloadAction<{
        carrierId: string;
        segmentOrder: number;
        abortUpdate?: boolean;
      }>
    ) => {
      const { abortUpdate, carrierId, segmentOrder } = action.payload;
      if (abortUpdate) return;

      state.trips[segmentOrder - 1].selected_carrier = carrierId;
    },
    setTimezoneUsage: (state, action: PayloadAction<TZ_USAGE>) => {
      state.timezone_usage = action.payload;
    },
    updateSelectedDivision: (state, action: PayloadAction<IDivisionsResponse | undefined>) => {
      state.selected_division = action.payload?.id;
      state.provider_division = action.payload;
    },
    updateSelectedCustomer: (state, action: PayloadAction<ClientResponse | undefined>) => {
      state.selected_customer = action.payload?.id;
      state.provider_customer = action.payload;
    },
    updateSelectedContact: (state, action: PayloadAction<IContactClient | undefined>) => {
      state.selected_contact = action.payload?.id;
      state.provider_contact = action.payload;
    },

    updateSelectedPrimaryClient: (state, action: PayloadAction<CLIENT_TYPE>) => {
      state.selected_primary_client = action.payload;
    },
    updateSelectedDispatcher: (state, action: PayloadAction<string | undefined>) => {
      state.selected_dispatcher = action.payload;
    },
    updateSelectedDriver: (
      state,
      action: PayloadAction<{
        driver: TripDriverInfo;
        index: number;
        segmentOrder: number;
        selectedTruck?: string;
        selectedCarrier?: string;
        selectedTrailer?: string;
      }>
    ) => {
      const { driver, index, segmentOrder, selectedTruck, selectedCarrier, selectedTrailer } =
        action.payload;
      const newDrivers = [...state.trips[segmentOrder - 1].selected_drivers];
      newDrivers[index] = driver;

      state.trips[segmentOrder - 1].selected_drivers = newDrivers;

      if (!state.order_id) {
        if (selectedTruck) {
          state.trips[segmentOrder - 1].selected_truck = selectedTruck;
        }
        if (selectedTrailer) {
          state.trips[segmentOrder - 1].selected_trailer = selectedTrailer;
        }
        if (selectedCarrier) {
          state.trips[segmentOrder - 1].selected_carrier = selectedCarrier;
        }
      }
    },
    updateSelectedDrivers: (
      state,
      action: PayloadAction<{ drivers: Partial<TripDriverInfo>[]; segmentOrder: number }>
    ) => {
      const { drivers, segmentOrder } = action.payload;

      state.trips[segmentOrder - 1].selected_drivers = drivers;
    },
    updateGrossPayEstimateData: (
      state,
      action: PayloadAction<{
        driverIndex: number;
        grossPayEstimateData: TripDriverOverrideData;
        segmentOrder: number;
      }>
    ) => {
      const { driverIndex, grossPayEstimateData, segmentOrder } = action.payload;
      const newDrivers = [...state.trips[segmentOrder - 1].selected_drivers];

      // newDrivers[driverIndex].driver_pay_override = grossPayEstimateData.gross_pay_estimate;
      newDrivers[driverIndex].driver_pay_override_data = grossPayEstimateData;

      state.trips[segmentOrder - 1].selected_drivers = newDrivers;
    },
    updateOtherPayItemsData: (
      state,
      action: PayloadAction<{
        driverIndex: number;
        otherPayItemsData: TripDriverOtherPay[];
        segmentOrder: number;
      }>
    ) => {
      const { driverIndex, otherPayItemsData, segmentOrder } = action.payload;
      const newDrivers = [...state.trips[segmentOrder - 1].selected_drivers];
      newDrivers[driverIndex].trip_driver_pay_other_payment = otherPayItemsData;

      state.trips[segmentOrder - 1].selected_drivers = newDrivers;
    },
    updateSendDriverLink: (
      state,
      action: PayloadAction<{ driverIndex: number; segmentOrder: number; value: boolean }>
    ) => {
      const { driverIndex, segmentOrder, value } = action.payload;
      const newDrivers = [...state.trips[segmentOrder - 1].selected_drivers];

      newDrivers[driverIndex].send_driver_link = value;

      state.trips[segmentOrder - 1].selected_drivers = newDrivers;
    },
    updateSelectedTrailer: (
      state,
      action: PayloadAction<{ segmentOrder: number; value?: string }>
    ) => {
      const { segmentOrder, value } = action.payload;

      state.trips[segmentOrder - 1].selected_trailer = value;
    },
    updateSelectedTruck: (
      state,
      action: PayloadAction<{ segmentOrder: number; value?: string; preserveEmptyMiles?: boolean }>
    ) => {
      const { segmentOrder, value, preserveEmptyMiles = false } = action.payload;

      state.trips[segmentOrder - 1].selected_truck = value;

      if (!preserveEmptyMiles) {
        state.trips[segmentOrder - 1].empty_miles = null;
      }
    },
    updateSelectedCarrier: (
      state,
      action: PayloadAction<{ segmentOrder: number; value?: string }>
    ) => {
      const { segmentOrder, value } = action.payload;

      state.trips[segmentOrder - 1].selected_carrier = value;
    },

    setRate: (state, action: PayloadAction<number | undefined>) => {
      const isSplitLoad = state.order_type === LOAD_TYPE.SEGMENT;

      if (isSplitLoad) {
        const segments = state.trips.map((segment, index) => {
          return {
            ...segment,
            selected_drivers: segment.selected_drivers.map((driver, index) => {
              return {
                ...driver,
                driver_pay_override: undefined,
                driver_pay_override_data: undefined,
                trip_driver_pay_other_payment: []
              };
            })
          };
        });

        state.trips = segments;
      } else {
        const driversExist = state.trips[0].selected_drivers.length > 0;

        if (driversExist) {
          state.trips[0].selected_drivers = state.trips[0].selected_drivers.map((driver, index) => {
            return {
              ...driver,
              driver_pay_override: undefined,
              driver_pay_override_data: undefined,
              trip_driver_pay_other_payment: []
            };
          });
        }
      }
      state.rate = action.payload;
    },
    setShipperLoadNumber: (state, action: PayloadAction<string>) => {
      state.customer_order_number = action.payload;
    },
    toggleAdvancedRates: (state, action: PayloadAction<boolean>) => {
      state.show_advanced_rates = action.payload;
    },
    updateLoadRates: (
      state,
      action: PayloadAction<{ rates: SliceOrderRate[]; resetDriverPayOverrides?: boolean }>
    ) => {
      // We check if we have drivers assigned
      // if we have, then we need to reset the drivers gross pay estimate
      const { resetDriverPayOverrides, rates } = action.payload;

      if (resetDriverPayOverrides === undefined || resetDriverPayOverrides === true) {
        const isSplitLoad = state.order_type === LOAD_TYPE.SEGMENT;

        if (isSplitLoad) {
          const segments = state.trips.map((segment) => {
            return {
              ...segment,
              selected_drivers: segment.selected_drivers.map((driver) => {
                return {
                  ...driver,
                  driver_pay_override: undefined,
                  driver_pay_override_data: undefined,
                  trip_driver_pay_other_payment: []
                };
              })
            };
          });

          state.trips = segments;
        } else {
          const driversExist = state.trips[0].selected_drivers.length > 0;

          if (driversExist) {
            state.trips[0].selected_drivers = state.trips[0].selected_drivers.map((driver) => {
              return {
                ...driver,
                driver_pay_override: undefined,
                driver_pay_override_data: undefined,
                trip_driver_pay_other_payment: []
              };
            });
          }
        }
      }

      state.order_rates = rates;
    },
    updateInitialLoadRates: (state, action: PayloadAction<SliceOrderRate[]>) => {
      state.initial_load_rates = action.payload;
    },
    toggleBrokeredLoad: (state, action: PayloadAction<boolean>) => {
      state.is_brokered_order = action.payload;
    },
    updatePayableAmount: (state, action: PayloadAction<number | undefined>) => {
      state.payable_amount = action.payload;
    },
    updateCommodity: (state, action: PayloadAction<OrderCommodities[]>) => {
      state.order_commodities = action.payload;
    },
    updateLoadNotificationSettings: (
      state,
      action: PayloadAction<LoadNotificationSettingsRequest | undefined>
    ) => {
      state.order_notification_settings = action.payload;
    },
    resetAdvancedRates: (state, action: PayloadAction<{ services: InvoiceService[] }>) => {
      const { services } = action.payload;
      const quickbooksEnabled = state.quickbooks_enabled;
      let service = services.find((service) => service.isDefault);

      // if no default service present use flat rate service
      if (!service) {
        service = services.find((service) => service.name === LINEHAUL_FLAT_RATE_NAME);
      }

      const defaultService = {
        ui_id: DEFAULT_LOAD_RATE_UI_ID,
        invoice_service_id: !quickbooksEnabled ? service?.id : undefined,
        qbo_service_id: quickbooksEnabled ? service?.id : undefined,
        qbo_service_type: quickbooksEnabled ? service?.type : undefined,
        service_name: service?.name,
        quantity: 1,
        unit: quickbooksEnabled && service?.isDefault ? InvoiceServiceUnit.FLAT : service?.unit,
        initial_service_option: service
          ? {
              id: service.id!,
              label: service.name,
              value: service
            }
          : undefined
      };

      state.initial_load_rates = [defaultService];
      state.order_rates = [defaultService];

      const segments = state.trips.map((segment) => {
        return {
          ...segment,
          selected_drivers: segment.selected_drivers.map((driver) => {
            return {
              ...driver,
              driver_pay_override: undefined,
              driver_pay_override_data: undefined,
              trip_driver_pay_other_payment: []
            };
          })
        };
      });
      state.trips = segments;
      state.load_rates_disabled = false;
      state.override_qbo_attributes = true;
    },
    updateQuickbooksEnabled: (state, action: PayloadAction<boolean>) => {
      state.quickbooks_enabled = action.payload;
    },
    updateLoadRatesDisabled: (state, action: PayloadAction<boolean>) => {
      state.load_rates_disabled = action.payload;
    },
    setSendLoadUpdate: (state, action: PayloadAction<boolean>) => {
      state.send_order_update = action.payload;
    },
    updateInstructions: (state, action: PayloadAction<string>) => {
      state.instructions = action.payload;
    },
    updateTripInstructions: (
      state,
      action: PayloadAction<{ segmentOrder: number; instructions: string }>
    ) => {
      const { segmentOrder, instructions } = action.payload;
      state.trips[segmentOrder - 1].instructions = instructions;
    },
    updateDocuments: (state, action: PayloadAction<OrdersTripsDocumentParams[]>) => {
      state.documents = action.payload;
    },
    updateAttachments: (state, action: PayloadAction<OrderAttachment[] | undefined>) => {
      state.attachments = action.payload;
    },
    setExistingDocuments: (state, action: PayloadAction<OrderAttachment[] | undefined>) => {
      state.existing_documents = action.payload;
    },
    updateExistingDocument: (state, action: PayloadAction<OrderAttachment>) => {
      const { id, ...rest } = action.payload;
      const newDocuments = state.existing_documents?.map((d) =>
        d.id === id ? { ...d, ...rest } : d
      );

      state.existing_documents = newDocuments;
    },
    addStopToSegmentInOrder: (
      state,
      action: PayloadAction<{
        segmentId: string;
        stopId: string;
        direction: 'before' | 'after';
        preserveMiles?: boolean;
      }>
    ) => {
      const { segmentId, stopId, direction, preserveMiles = false } = action.payload;
      const currentStopsLength = state.provider_stops.length;
      const currentSegments = [...state.trips];
      let newStops: SliceStop[] = [...state.provider_stops];
      const indexOfStopClickedToAdd = newStops.findIndex((stop) => stop.ui_id === stopId);
      const stopClicked = newStops[indexOfStopClickedToAdd];

      newStops = [
        ...newStops.slice(
          0,
          direction === 'before' ? indexOfStopClickedToAdd : indexOfStopClickedToAdd + 1
        ),
        {
          ...DEFAULT_STOP,
          order: stopClicked.order + 1,
          sort_order: stopClicked.order + 1,
          ui_id: nanoid(),
          segment_id: segmentId
        },
        ...newStops.slice(
          direction === 'before' ? indexOfStopClickedToAdd : indexOfStopClickedToAdd + 1
        )
      ];

      let ordering = 0;
      newStops.map((stop) => {
        if (!stop.is_shared_stop) {
          ordering++;
        }
        stop.order = ordering;
        stop.sort_order = ordering;
      });

      currentSegments.forEach((seg) => {
        let stopsFromSegment = newStops.filter(
          (stop: any) => stop.segment_id === seg.ui_id && !stop.is_shared_stop
        );

        if (state.trips.length > 1 && seg.order > 1) {
          const prevSegment = state.trips.find((prevS) => prevS.order === seg.order - 1);

          const lastStopFromPrev =
            prevSegment?.provider_stops[prevSegment?.provider_stops.length - 1];
          const { id, ...lastStopFromPrevAttrs } = lastStopFromPrev || {};
          if (lastStopFromPrev) {
            const newLastStopFromPrev = {
              ...lastStopFromPrevAttrs,
              is_shared_stop: true,
              order: lastStopFromPrev.order,
              sort_order: lastStopFromPrev.order,
              ui_id: nanoid(),
              segment_id: seg.ui_id,
              stop_type: STOP_TYPE.PICKUP,
              provider_stop_type: {
                id: STOP_TYPE.PICKUP,
                label: 'Pickup',
                value: STOP_TYPE.PICKUP
              }
            };
            stopsFromSegment = [newLastStopFromPrev, ...stopsFromSegment];
          }
        }

        seg.selected_drivers = seg.selected_drivers.map((driver, index) => {
          return {
            ...driver,
            driver_pay_override: undefined,
            driver_pay_override_data: undefined,
            trip_driver_pay_other_payment: []
          };
        });
        const stopsHaveLocation = stopsFromSegment.every((s) => s.provider_location);
        seg.empty_miles =
          !stopsFromSegment[0].provider_location && !preserveMiles ? null : seg.empty_miles;
        seg.estimated_mileage = stopsHaveLocation || preserveMiles ? seg.estimated_mileage : null;
        seg.provider_stops = stopsFromSegment;
      });

      state.trips = currentSegments;
      state.provider_stops = newStops;
    },
    reorderStopInSegment: (
      state,
      action: PayloadAction<{
        tripId: string;

        originalIndex: number;
        newIndex: number;
      }>
    ) => {
      const { tripId, originalIndex, newIndex } = action.payload;

      const tripWithStopToUpdate = state.trips.find((tr) => tr.ui_id === tripId);
      if (!tripWithStopToUpdate) return;

      const reorderedSubItems = reorder(
        tripWithStopToUpdate?.provider_stops,
        originalIndex,
        newIndex
      );

      const firstStopInSegmentOrder = tripWithStopToUpdate.provider_stops[0];
      if (!firstStopInSegmentOrder) return;

      let initialorder = firstStopInSegmentOrder.order;
      reorderedSubItems.forEach((s, i) => {
        s.order = initialorder;
        s.sort_order = initialorder;
        initialorder = ++initialorder;
        return s;
      });

      const newTrips = state.trips.map((trip: SliceTrip) => {
        if (trip.ui_id === tripId) {
          trip.provider_stops = reorderedSubItems;
        }
        return trip;
      });

      const newStops = [] as SliceStop[];
      newTrips.forEach((trip: SliceTrip) => {
        trip.provider_stops.forEach((stop: SliceStop) => {
          newStops.push({ ...stop });
        });
      });

      state.trips = newTrips;
      state.provider_stops = newStops;
    },
    splitSegmentAtStop: (
      state,
      action: PayloadAction<{ segmentId: string; stopId: string; preserveMiles?: boolean }>
    ) => {
      /**
       * Splits given segment at given stop order so we will have one segment each with stops before and after the split point
       */
      const { segmentId, stopId, preserveMiles = false } = action.payload;
      let currentSegments = [...state.trips];
      let newStops: SliceStop[] = [...state.provider_stops];
      let indexOfStopSplitPoint = newStops.findIndex((stop) => stop.ui_id === stopId);

      const currentSegment = currentSegments.find((seg) => seg.ui_id === segmentId);
      // Create new segment for above split point and add dispatch info from current segment
      const newAboveSplitSegment: SliceTrip = {
        ...DEFAULT_SEGMENT,
        ui_id: nanoid(),
        provider_stops: [],
        selected_drivers: currentSegment?.selected_drivers || [{ send_driver_link: false }],
        selected_truck: currentSegment?.selected_truck,
        selected_trailer: currentSegment?.selected_trailer,
        selected_carrier: currentSegment?.selected_carrier,
        instructions: currentSegment?.instructions,
        estimated_mileage: preserveMiles ? currentSegment?.estimated_mileage || null : null,
        empty_miles: currentSegment?.empty_miles || null
      };

      // reset dispatch info for current segment
      if (currentSegment) {
        currentSegment.selected_drivers = [{ send_driver_link: false }];
        currentSegment.selected_truck = '';
        currentSegment.selected_trailer = '';
        currentSegment.selected_carrier = '';
        currentSegment.instructions = '';
        currentSegment.estimated_mileage = null;
        currentSegment.empty_miles = null;
      }

      // Find all stops above split point of current segment

      const stopsAboveSplit = [] as SliceStop[];
      newStops.forEach((stop, i) => {
        if (stop.segment_id === segmentId && i < indexOfStopSplitPoint) {
          stopsAboveSplit.push(stop);
        }
      });

      // update all stops above split point to reference the new segment
      const stopsAboveSplitIds = stopsAboveSplit.map((stop) => stop.ui_id);
      newStops = newStops.map((stop) => {
        if (stopsAboveSplitIds.includes(stop.ui_id)) {
          stop.segment_id = newAboveSplitSegment.ui_id;
        }
        return stop;
      });

      // add all stops above split point to the new segment
      newAboveSplitSegment.provider_stops.push(...stopsAboveSplit);

      /**
       * if stopsAboveSplit is empty or just one
       *  then we need to add a new  empty stop to the new segment as each segment needs to have at least two stop
       *  */
      if (stopsAboveSplit.length < 2) {
        const newStop = {
          ...DEFAULT_STOP,
          order: state.provider_stops[indexOfStopSplitPoint].order,
          sort_order: state.provider_stops[indexOfStopSplitPoint].order,
          ui_id: nanoid(),
          segment_id: newAboveSplitSegment.ui_id
        };

        newAboveSplitSegment.provider_stops.push(newStop);

        newStops = [
          ...newStops.slice(0, indexOfStopSplitPoint),
          newStop,
          ...newStops.slice(indexOfStopSplitPoint)
        ];

        indexOfStopSplitPoint++;
      }

      // then add to the stops below the split point the previous stop info, just with
      // new property so we know is shared and the id to which segment belongs.
      const previousStopFromAboveSegment =
        newAboveSplitSegment.provider_stops[newAboveSplitSegment.provider_stops.length - 1];
      if (previousStopFromAboveSegment && currentSegment && currentSegment.ui_id) {
        const { id, ...prevStopAttrs } = previousStopFromAboveSegment;
        newStops = [
          ...newStops.slice(0, indexOfStopSplitPoint),
          {
            ...prevStopAttrs,
            is_shared_stop: true,
            ui_id: nanoid(),
            segment_id: currentSegment?.ui_id
          },
          ...newStops.slice(indexOfStopSplitPoint)
        ];
      }

      let ordering = 0;
      newStops.map((stop) => {
        if (!stop.is_shared_stop) {
          ordering++;
        }
        stop.order = ordering;
        stop.sort_order = ordering;
      });

      const segmentOrder = currentSegment?.order || 1;

      if (currentSegment) {
        // replace updated current segment
        currentSegments.splice(segmentOrder - 1, 1, currentSegment);
      }

      // add new segment in the correct order
      currentSegments.splice(segmentOrder - 1, 0, newAboveSplitSegment);

      currentSegments.forEach((seg, i) => {
        seg.order = i + 1;
        seg.sort_order = i + 1;
      });
      // Assign stops for segments
      currentSegments = currentSegments.map((seg) => {
        const stopsFromSegment = newStops.filter((s: any) => s.segment_id === seg.ui_id);

        seg.selected_drivers = seg.selected_drivers.map((driver) => {
          return {
            ...driver,
            driver_pay_override: undefined,
            driver_pay_override_data: undefined,
            trip_driver_pay_other_payment: []
          };
        });

        seg.provider_stops = stopsFromSegment;
        return seg;
      });

      state.order_type = LOAD_TYPE.SEGMENT;
      state.trips = currentSegments;
      state.provider_stops = newStops;
    },
    updateStopV2: (
      state,
      action: PayloadAction<{ stop: SliceStop; clearGrossPayDrivers?: boolean }>
    ) => {
      const { stop, clearGrossPayDrivers } = action.payload;

      const tripStopBelongs = state.trips.find((trip) => trip.ui_id === stop.segment_id);

      if (!tripStopBelongs) return;
      const isLastStopFromTrip =
        tripStopBelongs?.provider_stops.findIndex((tripStop) => tripStop.ui_id === stop.ui_id) ===
        tripStopBelongs?.provider_stops.length - 1;

      const newStops = [...state.provider_stops];
      const stopToUpdate = newStops.findIndex((s) => s.ui_id === stop.ui_id);
      // updating stop
      newStops[stopToUpdate] = stop;

      // if is last stop from trip and there is a shared stop after we update it
      if (isLastStopFromTrip) {
        // get the next trip
        const nextTrip = state.trips.find((trip) => trip.order === tripStopBelongs.order + 1);

        if (nextTrip) {
          newStops[stopToUpdate + 1] = {
            ...stop,
            is_shared_stop: true,
            provider_location: stop.provider_location,
            location: stop.location,
            location_id: stop.location_id,
            segment_id: nextTrip.ui_id,
            ui_id: newStops[stopToUpdate + 1].ui_id, // preserve the ui_id
            id: newStops[stopToUpdate + 1].id // preserve the id
          };
        }
      }

      const currentSegments = state.trips.map((seg) => {
        const stopsFromSegment = newStops.filter((s: any) => s.segment_id === seg.ui_id);
        return {
          ...seg,
          estimated_mileage: clearGrossPayDrivers ? null : seg.estimated_mileage,
          is_loaded_miles_auto: clearGrossPayDrivers ? true : seg.is_loaded_miles_auto,
          selected_drivers: clearGrossPayDrivers
            ? seg.selected_drivers.map((driver, index) => {
                return {
                  ...driver,
                  driver_pay_override: undefined,
                  driver_pay_override_data: undefined,
                  trip_driver_pay_other_payment: []
                };
              })
            : seg.selected_drivers,
          provider_stops: stopsFromSegment,
          empty_miles:
            clearGrossPayDrivers && seg.provider_stops[0].ui_id === stop.ui_id
              ? null
              : seg.empty_miles,
          is_empty_miles_auto:
            clearGrossPayDrivers && seg.provider_stops[0].ui_id === stop.ui_id
              ? false
              : seg.is_empty_miles_auto
        };
      });

      state.provider_stops = newStops;
      state.trips = currentSegments;
    },
    deleteStopV2: (state, action: PayloadAction<string>) => {
      const stopsToKeep = [...state.provider_stops];
      const stopToDelete = state.provider_stops.find((stop) => stop.ui_id === action.payload);
      if (!stopToDelete) return;

      const tripStopBelongs = state.trips.find((trip) => trip.ui_id === stopToDelete.segment_id);
      if (!tripStopBelongs) return;

      const isLastStopFromTrip =
        tripStopBelongs?.provider_stops.findIndex(
          (tripStop) => tripStop.ui_id === stopToDelete.ui_id
        ) ===
        tripStopBelongs?.provider_stops.length - 1;

      stopsToKeep.splice(
        stopsToKeep.findIndex((s) => s.ui_id === stopToDelete.ui_id),
        1
      );

      if (isLastStopFromTrip) {
        const previousStop = stopsToKeep.find((s) => s.order === stopToDelete.order - 1);
        if (!previousStop) return;
        // replace next sharedStop with previous stop
        const sharedStopToUpdate = stopsToKeep.find((s) => s.order === stopToDelete.order + 1);
        if (sharedStopToUpdate) {
          stopsToKeep[stopsToKeep.findIndex((s) => s.ui_id === sharedStopToUpdate.ui_id)] = {
            ...previousStop,
            is_shared_stop: true,
            segment_id: sharedStopToUpdate.segment_id,
            ui_id: sharedStopToUpdate.ui_id,
            id: sharedStopToUpdate.id
          };
        }
      }

      let ordering = 0;
      stopsToKeep.forEach((s, i) => {
        if (!s.is_shared_stop) {
          ordering++;
        }
        s.order = ordering;
        s.sort_order = ordering;
      });

      state.trips.forEach((seg, i) => {
        const stopsFromSegment = stopsToKeep.filter((s: any) => s.segment_id === seg.ui_id);

        seg.selected_drivers = seg.selected_drivers.map((driver, index) => {
          return {
            ...driver,
            driver_pay_override: undefined,
            driver_pay_override_data: undefined,
            trip_driver_pay_other_payment: []
          };
        });

        state.provider_stops = stopsToKeep;
        seg.provider_stops = stopsFromSegment;
      });
    },
    addLoadDriver: (
      state,
      action: PayloadAction<{ segmentOrder: number; callback?: () => void }>
    ) => {
      const { segmentOrder, callback } = action.payload;

      if (state.trips[segmentOrder - 1].selected_drivers.length > 2) return;

      const newDrivers = [...state.trips[segmentOrder - 1].selected_drivers];

      newDrivers.push({ send_driver_link: false });

      // When adding a new driver we clear the estimated gross pay for the previous drivers
      const newDriversCleared = newDrivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined,
          trip_driver_pay_other_payment: []
        };
      });
      state.trips[segmentOrder - 1].selected_drivers = newDriversCleared;

      callback && callback();
    },
    deleteDriver: (state, action: PayloadAction<{ segmentOrder: number; driverOrder: number }>) => {
      const { segmentOrder, driverOrder } = action.payload;
      const currentDrivers = [...state.trips[segmentOrder - 1].selected_drivers];
      const newDrivers = currentDrivers.filter((_, index) => index !== driverOrder - 1);

      // When removing a driver we clear the estimated gross pay for other drivers
      const newDriversCleared = newDrivers.map((driver) => {
        return {
          ...driver,
          driver_pay_override: undefined,
          driver_pay_override_data: undefined,
          trip_driver_pay_other_payment: []
        };
      });
      state.trips[segmentOrder - 1].selected_drivers = newDriversCleared;
    },
    addSegment: (state) => {
      const newSegments = [...state.trips];
      const newStops = [...state.provider_stops];

      const lastSegment = newSegments[newSegments.length - 1];
      const lastStopFromPrevSegment =
        lastSegment.provider_stops[lastSegment.provider_stops.length - 1];
      const newSegmentId = nanoid();
      const newStopId = nanoid();

      const { id, ...lastStopFromPrevSegmentAttrs } = lastStopFromPrevSegment;
      const newFirstStop: SliceStop = {
        ...lastStopFromPrevSegmentAttrs,
        is_shared_stop: true,
        order: lastStopFromPrevSegment.order,
        sort_order: lastStopFromPrevSegment.order,
        ui_id: nanoid(),
        segment_id: newSegmentId,
        stop_type: STOP_TYPE.PICKUP,
        provider_stop_type: {
          id: STOP_TYPE.PICKUP,
          label: 'Pickup',
          value: STOP_TYPE.PICKUP
        }
      };

      newStops.push(newFirstStop);

      newStops.push({
        ...DEFAULT_STOP,
        order: newFirstStop.order + 1,
        segment_id: newSegmentId,
        ui_id: newStopId
      });

      let ordering = 0;
      newStops.map((stop) => {
        if (!stop.is_shared_stop) {
          ordering++;
        }
        stop.order = ordering;
        stop.sort_order = ordering;
      });

      const stopsFromSegment = newStops.filter((s: any) => s.segment_id === newSegmentId);
      const newSegment = {
        ...DEFAULT_SEGMENT,
        order: lastSegment.order + 1,
        ui_id: newSegmentId,
        provider_stops: stopsFromSegment
      };

      newSegments.push(newSegment);

      state.provider_stops = newStops;
      state.trips = newSegments;
    },
    deleteSegment: (state, action: PayloadAction<string>) => {
      const segmentId = action.payload;

      const segmentsToKeep = state.trips.filter((segment) => {
        return segment.ui_id !== segmentId;
      });
      segmentsToKeep.forEach((seg, i) => {
        const newOrder = i + 1;
        const stopsFromSegment = state.provider_stops.filter(
          (s: any) => s.segment_id === seg.ui_id
        );

        // first trip first stop should not be shared
        if (i === 0) {
          stopsFromSegment[0].is_shared_stop = false;
        }

        seg.provider_stops = stopsFromSegment;
        seg.order = newOrder;
      });

      const stopsToKeep = [] as SliceStop[];

      const newSegmentsWithPrevStops = segmentsToKeep.map((seg, i) => {
        if (seg.order > 1) {
          const prevSegment = segmentsToKeep.find((prevS) => prevS.order === seg.order - 1);
          const lastStopFromPrev =
            prevSegment?.provider_stops[prevSegment?.provider_stops.length - 1];
          const firstStopOfSegment = seg.provider_stops[0];

          if (lastStopFromPrev) {
            const newSharedStopForTrip = {
              ...lastStopFromPrev,
              id: firstStopOfSegment.id,
              is_shared_stop: true,
              order: lastStopFromPrev.order,
              sort_order: lastStopFromPrev.order,
              ui_id: firstStopOfSegment.ui_id,
              segment_id: seg.ui_id,
              stop_type: STOP_TYPE.PICKUP,
              provider_stop_type: {
                id: STOP_TYPE.PICKUP,
                label: 'Pickup',
                value: STOP_TYPE.PICKUP
              }
            };

            seg.provider_stops = [newSharedStopForTrip, ...seg.provider_stops.slice(1)];
            // add to stopsToKeep for provider
            stopsToKeep.push(...seg.provider_stops);
          }
        } else {
          stopsToKeep.push(...seg.provider_stops);
        }

        return seg;
      });

      if (newSegmentsWithPrevStops.length === 1) {
        state.order_type = LOAD_TYPE.SIMPLE;
      }

      stopsToKeep.forEach((stop, i) => {
        stop.order = i + 1;
        stop.sort_order = i + 1;
      });
      state.trips = segmentsToKeep;
      state.provider_stops = stopsToKeep;
    },

    updateEmptyMilesSegment: (
      state,
      action: PayloadAction<{
        segmentUid: string;
        value: number | null;
        is_auto: boolean;
        source: PROVIDER;
      }>
    ) => {
      const { segmentUid, value, is_auto, source } = action.payload;

      const segments = state.trips.map((seg) => {
        if (seg.ui_id === segmentUid) {
          return {
            ...seg,
            empty_miles: value,
            is_empty_miles_auto: is_auto,
            empty_mileage_source: source
          };
        }
        return seg;
      });

      state.trips = segments;
    },
    updateEstimatedMileageSegment: (
      state,
      action: PayloadAction<{
        segmentId: string;
        value: number | null;
        is_auto: boolean;
        source: PROVIDER;
      }>
    ) => {
      const { segmentId, value, is_auto, source } = action.payload;
      const segments = state.trips.map((seg) => {
        if (seg.ui_id === segmentId) {
          return {
            ...seg,
            estimated_mileage: value,
            is_loaded_miles_auto: is_auto,
            loaded_mileage_source: source
          };
        }
        return seg;
      });

      state.trips = segments;
    },
    updateAutomaticMileage: (
      state,
      action: PayloadAction<{ segmentId: string; mileage: number | null }>
    ) => {
      const { segmentId, mileage } = action.payload;

      const segments = state.trips.map((seg) => {
        if (seg.ui_id === segmentId) {
          return {
            ...seg,
            automatic_mileage: mileage
          };
        }
        return seg;
      });

      state.trips = segments;

      // Add up all trips automatic mileage to get the total automatic mileage
      // (needed for Advanced rates miles calculation)
      const totalAutomaticMileage = segments.reduce((acc, seg) => {
        return acc + (seg.automatic_mileage || 0);
      }, 0);

      state.total_automatic_mileage = totalAutomaticMileage;
    },
    updateTotalAutomaticMileage: (state, action: PayloadAction<number>) => {
      state.total_automatic_mileage = action.payload;
    },
    updateEstimatedMileage: (state, action: PayloadAction<number>) => {
      state.estimated_mileage = action.payload;
    },
    setLoadCalendarReRenderId: (state, action: PayloadAction<string | undefined>) => {
      state.load_calendar_re_render_id = action.payload;
    },
    mergeAllSegments: (state, action: PayloadAction<boolean>) => {
      const preserveMiles = action.payload;
      const newSegments = [...state.trips];
      const newStops = [...state.provider_stops];

      // If total stops after merge are more than MAX_STOPS_COUNT, remove the extra stops
      if (newStops.length > MAX_STOPS_COUNT) {
        newStops.splice(MAX_STOPS_COUNT);
      }

      // Preserve the first segment's dispatch information (driver, truck and trailer)
      const firstSegment = newSegments[0];
      const firstSegmentDrivers = firstSegment.selected_drivers;
      const firstSegmentTruck = firstSegment.selected_truck;
      const firstSegmentTrailer = firstSegment.selected_trailer;

      // only get the stops that are not shared stops
      const uniqueStops = newStops.filter((stop) => !stop.is_shared_stop);

      // Assign first segment uuid to all stops
      uniqueStops.forEach((s, i) => {
        s.segment_id = firstSegment.ui_id;
        s.order = i + 1;
        s.sort_order = i + 1;
      });

      // Add all stops into one segment, keeping the first segment's dispatch information except for the drivers' pay
      // that is cleared
      const mergedSegment = {
        ...DEFAULT_SEGMENT,
        order: 1,
        ui_id: firstSegment.ui_id,
        provider_stops: uniqueStops,
        selected_drivers: firstSegmentDrivers.map((driver, index) => {
          return {
            ...driver,
            driver_pay_override: undefined,
            driver_pay_override_data: undefined
          };
        }),
        selected_truck: firstSegmentTruck,
        selected_trailer: firstSegmentTrailer
      };

      if (preserveMiles) {
        mergedSegment.empty_miles = firstSegment.empty_miles;
        mergedSegment.estimated_mileage = firstSegment.estimated_mileage;
        mergedSegment.is_loaded_miles_auto = firstSegment.is_loaded_miles_auto;
        mergedSegment.is_empty_miles_auto = firstSegment.is_empty_miles_auto;
      }

      state.trips = [mergedSegment];
      state.provider_stops = uniqueStops;
      state.order_type = LOAD_TYPE.SIMPLE;
    },
    setOrdersForConsolidation: (
      state,
      action: PayloadAction<{ id: string; readable_id: string | number }[]>
    ) => {
      state.orders_for_consolidation = action.payload;
    },
    updateDriverPayConfirmationModal: (
      state,
      action: PayloadAction<{ open: boolean; action: null | (() => void) }>
    ) => {
      state.driver_pay_confirmation_modal = action.payload;
    }
  }
});

export const {
  setLoadState,
  clearLoadState,
  setOrderId,
  setRateTableId,
  // setOrderType,
  setBookingType,
  setSelectedTripId,
  updateSelectedStatus,
  setDuplicateLoad,
  setInvoiceLoadIds,
  setInvoiceDivisionId,
  addCustomer,
  updateSelectedDivision,
  updateSelectedCustomer,
  updateSelectedContact,
  updateSelectedPrimaryClient,
  updateSelectedDispatcher,
  setRate,
  setShipperLoadNumber,
  toggleAdvancedRates,
  updateLoadRates,
  updateInitialLoadRates,
  toggleBrokeredLoad,
  updatePayableAmount,
  updateCommodity,
  updateLoadNotificationSettings,
  resetAdvancedRates,
  updateQuickbooksEnabled,
  updateLoadRatesDisabled,
  setSendLoadUpdate,
  updateInstructions,
  updateDocuments,
  updateAttachments,
  setExistingDocuments,
  updateExistingDocument,
  addCarrier,
  addDriver,
  addEquipment,
  setTimezoneUsage,
  updateSelectedDriver,
  updateSelectedDrivers,
  updateSelectedTrailer,
  updateSelectedTruck,
  updateSelectedCarrier,
  updateGrossPayEstimateData,
  updateOtherPayItemsData,
  updateSendDriverLink,

  addStopToSegmentInOrder,
  reorderStopInSegment,
  splitSegmentAtStop,
  updateStopV2,
  deleteStopV2,
  addLoadDriver,
  deleteDriver,
  addSegment,
  deleteSegment,
  updateEstimatedMileage,
  updateEmptyMilesSegment,
  updateEstimatedMileageSegment,
  updateAutomaticMileage,
  updateTotalAutomaticMileage,
  setLoadCalendarReRenderId,
  mergeAllSegments,
  updateTripInstructions,
  setOrdersForConsolidation,
  updateDriverPayConfirmationModal
} = ordersSlice.actions;

// Selectors
export const selectTrips = (state: RootState): SliceTrip[] => state.orders.trips;
const selectTripId = (state: RootState, tripId: string): string => tripId;
export const selectIsManualLoadedMilesPresetByTripId = createSelector(
  [selectTrips, selectTripId],
  (trips, tripId) => {
    const trip = trips.find((trip) => trip.ui_id === tripId);
    return !trip?.is_loaded_miles_auto && !!trip?.estimated_mileage;
  }
);

export default ordersSlice.reducer;
