import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { NavigationPanel, LoadsTab, MobileNavItems, PinnableTabs } from './navigation.model';
import { LoadSearchParams } from '../../api/load/load.model';

export interface INavigationState {
  isCalendarOpen: boolean;
  isCreateInvoiceOpen: boolean;
  isEditInvoiceOpen: boolean;
  isEditLoadOpen: boolean;
  isUploadBolOpen: boolean;
  isLoadDetailOpen: boolean;
  isTripDetailOpen: boolean;
  isCreateLoadOpen: boolean;
  isCreateOrderOpen: boolean;
  isPdfViewerOpen: boolean;
  isShareLoadOpen: boolean;
  isInvoicePreviewOpen: boolean;
  isSendDocumentsOpen: boolean;
  keepLoadDetailOpen: boolean;
  isCreateLoadFromFileOpen: boolean;
  isMobileMenuOpen: boolean;
  activePanel: NavigationPanel;
  activeTab?: MobileNavItems | undefined;
  loadsTab: LoadsTab;
  loadsFilters: LoadSearchParams | undefined;
  refIsFilterFromDashboard?: React.MutableRefObject<boolean>;
  loadDetailReload: boolean; // remove this when load details is in rtk query
  tripDetailReload: boolean;
  anchorId: string | null;
  isDeleteConfirmationOpen: boolean;
  isDeleteTripConfirmationOpen: boolean; // LTL-specific
  isEditDateTimeOpen: boolean;
  isImportLoadOpen: boolean;
  isCreateTripOpen: boolean; // LTL-specific
  isEditTripOpen: boolean; // LTL-specific
  isMobileTabDropdownOpen: boolean;
  isNotificationBannerAcknowledged: boolean;
  entityOverlayInitialTabId?: string | null;
  isCreateManifestOpen: boolean;

  // Pinned tabs
  ordersPinnedTab?: PinnableTabs;
  tripsPinnedTab?: PinnableTabs;
}

const initialState: INavigationState = {
  isCalendarOpen: false,
  isCreateLoadOpen: false,
  isCreateOrderOpen: false,
  keepLoadDetailOpen: false,
  isCreateLoadFromFileOpen: false,
  isMobileMenuOpen: false,
  activePanel: NavigationPanel.Dashboard,
  activeTab: MobileNavItems.ORDERS,
  loadsTab: LoadsTab.ACTIVE_LOADS,
  loadsFilters: undefined,
  refIsFilterFromDashboard: undefined,
  isCreateInvoiceOpen: false,
  isEditInvoiceOpen: false,
  isEditLoadOpen: false,
  isUploadBolOpen: false,
  isLoadDetailOpen: false,
  isTripDetailOpen: false,
  isPdfViewerOpen: false,
  isShareLoadOpen: false,
  isInvoicePreviewOpen: false,
  loadDetailReload: false,
  tripDetailReload: false,
  isSendDocumentsOpen: false,
  anchorId: null,
  isDeleteConfirmationOpen: false,
  isDeleteTripConfirmationOpen: false,
  isEditDateTimeOpen: false,
  isImportLoadOpen: false,
  isCreateTripOpen: false,
  isEditTripOpen: false,
  isMobileTabDropdownOpen: false,
  isNotificationBannerAcknowledged: false,
  entityOverlayInitialTabId: null,
  ordersPinnedTab: undefined,
  tripsPinnedTab: undefined,
  isCreateManifestOpen: false
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    openCalendar: (state) => {
      state.isCalendarOpen = true;
    },
    closeCalendar: (state) => {
      state.isCalendarOpen = false;
    },
    openCreateLoad: (state) => {
      state.isCreateLoadOpen = true;
    },
    openCreateOrder: (state) => {
      state.isCreateOrderOpen = true;
    },
    closeCreateOrder: (state) => {
      state.isCreateOrderOpen = false;
    },
    closeCreateLoad: (state) => {
      state.isCreateLoadOpen = false;
    },
    openCreateLoadFromFile: (state) => {
      state.isCreateLoadFromFileOpen = true;
    },
    closeCreateLoadFromFile: (state) => {
      state.isCreateLoadFromFileOpen = false;
    },
    openMobileMenu: (state) => {
      state.isMobileMenuOpen = true;
    },
    closeMobileMenu: (state) => {
      state.isMobileMenuOpen = false;
    },
    updateActivePanel: (state, action: PayloadAction<NavigationPanel>) => {
      state.activePanel = action.payload;
    },
    updateLoadsTab: (state, action: PayloadAction<LoadsTab>) => {
      state.loadsTab = action.payload;
    },
    updateLoadsFilters: (state, action: PayloadAction<LoadSearchParams | undefined>) => {
      state.loadsFilters = action.payload;
    },
    clearLoadsFilters: (state) => {
      state.loadsFilters = undefined;
    },
    updateRefIsFilterFromDashboard: (
      state,
      action: PayloadAction<React.MutableRefObject<boolean> | undefined>
    ) => {
      state.refIsFilterFromDashboard = action.payload;
    },
    keepLoadDetailOpen: (state) => {
      state.keepLoadDetailOpen = true;
    },
    resetKeepLoadDetailOpen: (state) => {
      state.keepLoadDetailOpen = false;
    },
    openCreateInvoice: (state) => {
      state.isCreateInvoiceOpen = true;
    },
    closeCreateInvoice: (state) => {
      state.isCreateInvoiceOpen = false;
    },
    openEditInvoice: (state) => {
      state.isEditInvoiceOpen = true;
    },
    closeEditInvoice: (state) => {
      state.isEditInvoiceOpen = false;
    },
    openEditLoad: (state) => {
      state.isEditLoadOpen = true;
    },
    closeEditLoad: (state) => {
      state.isEditLoadOpen = false;
    },
    openUploadBol: (state) => {
      state.isUploadBolOpen = true;
    },
    closeUploadBol: (state) => {
      state.isUploadBolOpen = false;
    },
    openLoadDetail: (state) => {
      state.isLoadDetailOpen = true;
    },
    closeLoadDetail: (state) => {
      state.isLoadDetailOpen = false;
    },
    openTripDetail: (state) => {
      state.isTripDetailOpen = true;
    },
    closeTripDetail: (state) => {
      state.isTripDetailOpen = false;
    },
    openPdfViewer: (state) => {
      state.isPdfViewerOpen = true;
    },
    closePdfViewer: (state) => {
      state.isPdfViewerOpen = false;
    },
    openShareLoad: (state) => {
      state.isShareLoadOpen = true;
    },
    closeShareLoad: (state) => {
      state.isShareLoadOpen = false;
    },
    openInvoicePreview: (state) => {
      state.isInvoicePreviewOpen = true;
    },
    closeInvoicePreview: (state) => {
      state.isInvoicePreviewOpen = false;
    },
    openSendDocuments: (state) => {
      state.isSendDocumentsOpen = true;
    },
    closeSendDocuments: (state) => {
      state.isSendDocumentsOpen = false;
    },
    setLoadDetailReload: (state, action: PayloadAction<boolean>) => {
      state.loadDetailReload = action.payload;
    },
    setTripDetailReload: (state, action: PayloadAction<boolean>) => {
      state.tripDetailReload = action.payload;
    },
    setAnchorId: (state, action: PayloadAction<string | null>) => {
      state.anchorId = action.payload;
    },
    openDeleteLoadConfirmation: (state) => {
      state.isDeleteConfirmationOpen = true;
    },
    closeDeleteLoadConfirmation: (state) => {
      state.isDeleteConfirmationOpen = false;
    },
    openDeleteTripConfirmation: (state) => {
      state.isDeleteTripConfirmationOpen = true;
    },
    closeDeleteTripConfirmation: (state) => {
      state.isDeleteTripConfirmationOpen = false;
    },
    openEditDateTime: (state) => {
      state.isEditDateTimeOpen = true;
    },
    closeEditDateTime: (state) => {
      state.isEditDateTimeOpen = false;
    },
    openImportLoad: (state) => {
      state.isImportLoadOpen = true;
    },
    closeImportLoad: (state) => {
      state.isImportLoadOpen = false;
    },
    openCreateTrip: (state, action: PayloadAction<boolean>) => {
      state.isCreateTripOpen = action.payload;
    },
    openEditTrip: (state, action: PayloadAction<boolean>) => {
      state.isEditTripOpen = action.payload;
    },
    openMobileTabDropdown: (state) => {
      state.isMobileTabDropdownOpen = true;
    },
    closeMobileTabDropdown: (state) => {
      state.isMobileTabDropdownOpen = false;
    },
    closeNotificationBanner: (state) => {
      state.isNotificationBannerAcknowledged = true;
    },
    selectActiveTab: (state, action: PayloadAction<MobileNavItems>) => {
      state.activeTab = action.payload;
    },
    setEntityOverlayInitialTabId: (state, action: PayloadAction<string | null>) => {
      state.entityOverlayInitialTabId = action.payload;
    },
    // Pinned tabs state
    pinOrdersTab: (state, action: PayloadAction<PinnableTabs>) => {
      state.ordersPinnedTab = action.payload;
    },
    unpinOrdersTab: (state) => {
      state.ordersPinnedTab = undefined;
    },
    pinTripsTab: (state, action: PayloadAction<PinnableTabs>) => {
      state.tripsPinnedTab = action.payload;
    },
    unpinTripsTab: (state) => {
      state.tripsPinnedTab = undefined;
    },
    openCreateManifestPage: (state, action) => {
      state.isCreateManifestOpen = action.payload;
    }
  }
});

export const selectIsCreateLoadOpen = (state: RootState): boolean =>
  state.navigation.isCreateLoadOpen;
export const selectIsCreateOrderOpen = (state: RootState): boolean =>
  state.navigation.isCreateOrderOpen;
export const selectKeepLoadDetailOpen = (state: RootState): boolean =>
  state.navigation.keepLoadDetailOpen;
export const selectIsCreateLoadFromFileOpen = (state: RootState): boolean =>
  state.navigation.isCreateLoadFromFileOpen;
export const selectIsMobileMenuOpen = (state: RootState): boolean =>
  state.navigation.isMobileMenuOpen;
export const selectActivePanel = (state: RootState): NavigationPanel =>
  state.navigation.activePanel;
export const selectLoadsTab = (state: RootState): LoadsTab => state.navigation.loadsTab;
export const selectLoadsFilters = (state: RootState): LoadSearchParams | undefined =>
  state.navigation.loadsFilters;
export const selectRefIsFilterFromDashboard = (
  state: RootState
): React.MutableRefObject<boolean> | undefined => state.navigation.refIsFilterFromDashboard;
export const selectAnchorId = (state: RootState): string | null => state.navigation.anchorId;

// Pinned tabs selectors
export const selectOrdersPinnedTab = (state: RootState): PinnableTabs | undefined =>
  state.navigation.ordersPinnedTab;
export const selectTripsPinnedTab = (state: RootState): PinnableTabs | undefined =>
  state.navigation.tripsPinnedTab;

export const {
  openCalendar,
  closeCalendar,
  openCreateLoad,
  closeCreateLoad,
  openCreateOrder,
  closeCreateOrder,
  keepLoadDetailOpen,
  resetKeepLoadDetailOpen,
  openCreateLoadFromFile,
  closeCreateLoadFromFile,
  openMobileMenu,
  closeMobileMenu,
  updateActivePanel,
  updateLoadsTab,
  updateLoadsFilters,
  clearLoadsFilters,
  updateRefIsFilterFromDashboard,
  openCreateInvoice,
  closeCreateInvoice,
  openEditInvoice,
  closeEditInvoice,
  openEditLoad,
  closeEditLoad,
  openUploadBol,
  closeUploadBol,
  openLoadDetail,
  closeLoadDetail,
  openTripDetail,
  closeTripDetail,
  openPdfViewer,
  closePdfViewer,
  openShareLoad,
  closeShareLoad,
  openInvoicePreview,
  closeInvoicePreview,
  openSendDocuments,
  closeSendDocuments,
  setLoadDetailReload,
  setTripDetailReload,
  setAnchorId,
  openDeleteLoadConfirmation,
  closeDeleteLoadConfirmation,
  openDeleteTripConfirmation,
  closeDeleteTripConfirmation,
  openEditDateTime,
  closeEditDateTime,
  openImportLoad,
  closeImportLoad,
  openCreateTrip,
  openEditTrip,
  openMobileTabDropdown,
  closeMobileTabDropdown,
  closeNotificationBanner,
  selectActiveTab,
  setEntityOverlayInitialTabId,
  pinOrdersTab,
  unpinOrdersTab,
  pinTripsTab,
  unpinTripsTab,
  openCreateManifestPage
} = navigationSlice.actions;
export default navigationSlice.reducer;
