import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { ISearchInvoiceParams } from '../../../providers/Invoices/Invoices.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface InvoiceState {
  selectedInvoices: GridRowSelectionModel;
  isSelectAllInvoice: boolean;
  filterParams: ISearchInvoiceParams | undefined;
  openCreateEditFuelSurchargeTableModal: boolean;
}

export const initialInvoiceState: InvoiceState = {
  selectedInvoices: [],
  isSelectAllInvoice: false,
  filterParams: undefined,
  openCreateEditFuelSurchargeTableModal: false
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: initialInvoiceState,
  reducers: {
    updateSelectedInvoices: (state, action: PayloadAction<GridRowSelectionModel>) => {
      state.selectedInvoices = action.payload;
    },
    updateIsSelectAllInvoice: (state, action: PayloadAction<boolean>) => {
      state.isSelectAllInvoice = action.payload;
    },
    updateFilterParams: (state, action: PayloadAction<ISearchInvoiceParams>) => {
      state.filterParams = action.payload;
    },
    updateOpenCreateEditFuelSurchargeTableModal: (state, action: PayloadAction<boolean>) => {
      state.openCreateEditFuelSurchargeTableModal = action.payload;
    }
  }
});

export const {
  updateSelectedInvoices,
  updateIsSelectAllInvoice,
  updateFilterParams,
  updateOpenCreateEditFuelSurchargeTableModal
} = invoiceSlice.actions;

export const selectSelectedInvoices = (state: RootState): GridRowSelectionModel =>
  state.invoice.selectedInvoices;

export const selectIsSelectAllInvoice = (state: RootState): boolean =>
  state.invoice.isSelectAllInvoice;

export const selectFilterParams = (state: RootState): ISearchInvoiceParams | undefined =>
  state.invoice.filterParams;

export const selectOpenCreateEditFuelSurchargeTableModal = (state: RootState): boolean =>
  state.invoice.openCreateEditFuelSurchargeTableModal;

export default invoiceSlice.reducer;
